import webConfig from "@/config/web.config";
let webLanguage =
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage;

const languagePatterns = {
    fr: /^fr/i,
    de: /^de/i,
    es: /^es/i,
    it: /^it/i,
    ja: /^ja/i,
    ar: /^ar/i,
};
for (const [lang, pattern] of Object.entries(languagePatterns)) {
    if (pattern.test(webLanguage)) {
        webLanguage = lang;
        break;
    }
}

if (!webConfig.languageList.includes(webLanguage)) {
    webLanguage = "en"
}
export default webLanguage
<template>
    <div class="templateMultiOption template  ">

        <Head></Head>
        <div class="template-content" v-if="animationLoading && templateData">
            <div class="animation">
                <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
                <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].mainText"></div>
                <van-checkbox-group class="option-group" v-model="templateValue">
                    <van-checkbox @click="checkOption(item.originName, index)" class="option-item"
                        :class="{ hiddenCheckBox: templateData.hidenCheckBox, 'margin-inline-start': templateData.imageFull && templateData.imagePosition == 'left', 'margin-right': templateData.imageFull && templateData.imagePosition == 'right', 'shrink-grow-effect': activeIndex === index }"
                        :name="item.originName" label-position="left"
                        v-for="(item, index) in templateData.languageConfig[$language].optionList" :key="index">
                        <div class="option  flex justify-between align-center pointer">
                            <!-- 左侧图片 -->
                            <img v-if="templateData.imageList[index] && templateData.imagePosition == 'left'"
                                class="option-img flip-image" :height="templateData.imageSize"
                                :src="require(`@img/template/${templateData.imageList[index]}`)" alt="">
                            <div class="text-warp flex justify-between align-center  flex-auto">
                                <div class="content flex-auto">
                                    <div class="text text_1 flex-auto" v-html="item.option"></div>
                                    <div class="sub-text text_3  flex-auto" v-html="item.subOption"></div>
                                </div>
                            </div>
                            <!-- 右侧图片 -->
                            <img v-if="templateData.imageList[index] && templateData.imagePosition == 'right'"
                                class="option-img flip-image" :height="templateData.imageSize"
                                :src="require(`@img/template/${templateData.imageList[index]}`)" alt="">
                        </div>
                    </van-checkbox>

                </van-checkbox-group>

            </div>
        </div>
        <div class="btn-warp" v-show="animationLoading && templateData">
            <div @click="contimueClick(true), setOptionClickAnimation(-1)" class="button  text_1"
                :class="{ active: templateValue && templateValue.length, 'shrink-grow-effect': activeIndex === -1 }"
                v-html="templateData.languageConfig[$language].buttonText">
            </div>
        </div>
    </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";

export default {
    name: "templateMultiOption",
    mixins: [mixinTemplate],
    components: {
        Head
    },
    methods: {
        checkOption(option, index) {
            this.setOptionClickAnimation(index);
            //选项选择上限
            let maxOptionNum = this.templateData.maxOptionNum;
            if (maxOptionNum && this.templateValue.length > maxOptionNum) {
                this.templateValue = this.templateValue.slice(0, maxOptionNum)
            }
            // 选项有互斥
            let mutualExclusionIndex = this.templateData.mutualExclusionIndex;
            if (mutualExclusionIndex != null || mutualExclusionIndex != undefined) {
                let mutualExclusionOption = this.templateData.languageConfig[this.$language].optionList[mutualExclusionIndex].originName;
                if (mutualExclusionIndex == index) {
                    this.templateValue = [option]
                } else {
                    if (mutualExclusionOption) {
                        this.templateValue = this.templateValue.filter(item => item != mutualExclusionOption)
                    }

                }
            }


        }
    }
}
</script>
<style lang="scss">
.on-body .template .option-group .option-item.hiddenCheckBox .van-checkbox__icon {
    width: 0 !important;
    overflow: hidden;
}

.on-body .template .option-group .option-item.margin-right .van-checkbox__label--left {
    margin: 0 !important;
}

.templateMultiOption {


    .option-group {
        margin-top: 30px;

        .option-item {
            padding: 0 10px;

            .text-warp {
                padding: 10px 0;
                margin-inline-end: 5px;



                .text {
                    margin-inline-start: 24px;
                    color: var(--theme_01);
                }

                .sub-text {
                    margin-inline-start: 10px;
                    color: var(--theme_02);
                }
            }
        }

        .margin-inline-start {

            padding-inline-start: 0 !important;
        }

        .margin-right {

            padding-inline-end: 0 !important;



        }

    }
}
</style>
<template>
    <div class="progressLoading flex align-center ">
        <van-circle v-model="percentageValue" :rate="rateValue / 10" size="60" :color="circleColor"
            layer-color="#F3F3F8" :speed="100" :text="(parseInt(percentageValue)) + '%'" stroke-width="100" />
        <div class="text flex auto text_2 ">{{ $t.Creating }}</div>
    </div>
</template>
<script>
import mainConfig from "@/config/onboarding.config/main.json"
export default {
    name: "progressLoading",
    data() {
        return {
            circleColor: '',
            ties: new Date(),
            percentageValue: 0,
            rateValue: 0,
            intervalTimer: null,
            progressIndex: 0,
            //速度配置项
            progressList: [
                {
                    time: 150,
                    progressDiff: 10,
                    rateValue: 140,
                },
                {
                    time: 500,
                    progressDiff: 10,
                    rateValue: 170,
                },
                {
                    time: 50,
                    progressDiff: 10,
                    rateValue: 650,
                },
                {
                    time: 150,
                    progressDiff: 10,
                    rateValue: 720,
                },
                {
                    time: 50,
                    progressDiff: 20,
                    rateValue: 860,
                },
                {
                    time: 100,
                    progressDiff: 10,
                    rateValue: 920,
                },
                {
                    time: 500,
                    progressDiff: 10,
                    rateValue: 960,
                },
                {
                    time: 50,
                    progressDiff: 10,
                    rateValue: 990,
                },
                {
                    time: 100,
                    progressDiff: 10,
                    rateValue: 1000,
                }
            ]

        }
    },
    watch: {
        progressIndex: {
            handler(newVal) {
                if (newVal < this.progressList.length) {
                    this.animateLoading()
                }
            },
            immediate: true
        }
    },
    created() {
        this.circleColor = mainConfig.colorConfig.mainColor;
    },
    methods: {
        // 模拟加载进度
        animateLoading() {
            let progressInfo = this.progressList[this.progressIndex];
            if (this.rateValue < 1000) {
                if (this.rateValue >= progressInfo.rateValue) {
                    clearInterval(this.intervalTimer);
                    this.intervalTimer = null;
                    this.progressIndex++
                } else {
                    this.intervalTimer = setTimeout(() => {
                        this.rateValue += progressInfo.progressDiff;
                        this.animateLoading()
                    }, progressInfo.time);
                }
            } else {
                setTimeout(() => {
                    this.$emit('contimueClick')
                }, 500);
            }
        }
    }
}
</script>
<style scoped lang="scss">
.progressLoading {
    .text {
        margin-inline-start: 10px;
        color: var(--theme_02);
    }
}
</style>
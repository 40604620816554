<template>
  <div class="templateChart ">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
        <div class="sub-title text_2 tc" v-html="templateData.languageConfig[$language].subTitle"></div>
        <div class="content title_2 tc underLine">
          <span>
            {{ `${getTargetWeight} ${$t.by} ${complatetargetDate}` }}
          </span>
        </div>
        <div class="content-text text_3 theme_01 tc" v-if="!eventDateSkip && eventScreenData">
          {{ $t.GetReadyTo }} <div class="flex align-center justify-center" style="font-weight: 600;">

            <img style="margin-inline-end: 3px;" v-if="eventScreenData?.imageList[eventScreenIndex]"
              :src="require(`@img/template/${eventScreenData?.imageList[eventScreenIndex]}`)" alt="" width="19">
            {{ $store.state.onBoardingRecordInfo['eventScreenOption'] }}
          </div>
        </div>
        <div class="chart-warp">
          <lineChart v-if="currentWeightScreen" :currentWeightScreen="currentWeightScreen"
            :targetWeightScreen="targetWeightScreen" :complatetargetDate="complatetargetDate"
            :weightUnit="$store.state.onBoardingRecordInfo[$utils.getKey('weightUnit')]" />
        </div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="contimueClick(), setOptionClickAnimation(-1)" class="button active text_1"
        :class="{ 'shrink-grow-effect': activeIndex === -1 }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import lineChart from "@/components/echarts/lineChart";
import mixinTemplate from "@/mixin";
import eventScreenData from "@/config/onboarding.config/eventScreen"
import webConfig from "@/config/web.config/index";
export default {
  name: "templateChart",
  mixins: [mixinTemplate],
  components: {
    Head,
    lineChart,
  },
  data() {
    return {
      currentWeightScreen: 0,
      targetWeightScreen: 0,
      complatetargetDate: "",
      eventDateSkip: false, //是否跳过日期选择
      eventScreenIndex: 0,
      eventScreenData: null
    };
  },
  created() {
    if (eventScreenData && eventScreenData.languageConfig[this.$language].optionList) {
      this.eventScreenData = eventScreenData;
      console.log(eventScreenData.languageConfig[this.$language].optionList);
      this.eventScreenIndex = eventScreenData.languageConfig[this.$language].optionList.findIndex(item => item.originName == this.$store.state.onBoardingRecordInfo[this.$utils.getKey('eventScreen')])

    }
  },
  mounted() {
    let currentWeightScreen = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')];
    let targetWeightScreen = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')];
    currentWeightScreen = (webConfig.weightType == 0 ? currentWeightScreen : this.$utils.lbsToKg(currentWeightScreen));
    targetWeightScreen = (webConfig.weightType == 0 ? targetWeightScreen : this.$utils.lbsToKg(targetWeightScreen));
    this.currentWeightScreen = currentWeightScreen;
    this.targetWeightScreen = targetWeightScreen
    this.getComplatetargetDate();
    this.eventDateSkip = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('eventDateSkip')];
  },
  computed: {
    getTargetWeight() {
      let weightUnit = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('weightUnit')];
      let targetWeightScreen = this.$store.state.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')];
      return this.$utils.getDisplayWeight(targetWeightScreen, weightUnit)

    }
  },
  methods: {
    // 获取完成目标计划的日期1周1kg
    getComplatetargetDate() {
      if (this.currentWeightScreen && this.targetWeightScreen) {
        if (this.currentWeightScreen == this.targetWeightScreen) {
          this.complatetargetDate = this.$utils.getDate(new Date(), "MMM DD, YYYY");
        } else {
          let weightDifference = this.targetWeightScreen - this.currentWeightScreen;
          weightDifference = (weightDifference > 0 ? weightDifference : -weightDifference);
          this.complatetargetDate = this.$utils.getAnyDay(
            Math.round(weightDifference * 7),
            "MMM DD, YYYY"
          );
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.templateChart {


  .content {
    margin: 20px 0;
  }

  .underLine {
    text-decoration: underline;
    // border-bottom: 1px solid var(--theme_02);
  }

  .chart-warp {
    margin: 30px 0;
    height: 300px;
    background: var(--theme_03);
    border-radius: 14px;
    box-shadow: 0 0 8px #cbcbcb;
  }
}
</style>

<template>
  <div class="templateHeight">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
        <div class="type-tabs">
          <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
            v-for="item in typeList" :key="item">
            {{ item }}
          </div>
        </div>
        <div class="ipt-warp flex" v-show="currentType == 'in'">
          <div class="ipt-item">
            <van-field type="number" class="ipt" @focus="clearDefaultValue($event, 1)"
              @blur="clearDefaultValue($event, 2)" :style="{ width: iptWidth1 + 'px' }"
              :class="{ noData: ftValue == 0 }" v-model="ftValue" />
            <span>ft</span>
          </div>
          <div class="ipt-item">
            <van-field ref="iptInRef" type="number" @focus="clearDefaultValue($event, 1)"
              @blur="clearDefaultValue($event, 2)" class="ipt" :style="{ width: iptWidth2 + 'px' }"
              :class="{ noData: inValue == 0 }" v-model="inValue" />
            <span>in</span>
          </div>
        </div>
        <span class="weightInputSpan" ref="weightInputSpanRef"></span>
        <div class="ipt-warp flex" v-show="currentType == 'cm'">
          <div class="ipt-item">
            <van-field type="number" class="ipt" :style="{ width: iptWidth3 + 'px' }"
              @focus="clearDefaultValue($event, 1)" @blur="clearDefaultValue($event, 2)"
              :class="{ noData: cmValue == 0 }" v-model="cmValue" />
            <span>cm</span>
          </div>
        </div>
        <div class="ipt-tip title_3 tip-color tc">
          <span class="amimation-height" :class="isTipVisible ? 'tip-visible' : 'hidden'">
            {{ getInputTip }}</span>
        </div>

        <div class="height-tip">
          <div class="title text_1 flex align-center">
            <img class="img" src="@img/ruler.png" width="24" alt="" />
            <div class="flex-auto">{{ $t.Calculating }}</div>
          </div>
          <div class="sub-title text_3">
            {{ $t.BMIIsCommonly }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="validateClick(), setOptionClickAnimation(-1)" class="button text_1"
        :class="{ active: validInputValue(), 'shrink-grow-effect': activeIndex === -1 }"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";

export default {
  name: "templateHeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      iptWidth1: 40,
      iptWidth2: 40,
      iptWidth3: 40,
      typeList: ["in", "cm"],
      currentType: "in",
      ftValue: 0,
      inValue: 0,
      cmValue: 0,
      maxIn: 11,
      minIn: 0,
      minValue: 0,
      minFt: 4,
      maxFt: 7,
      minCm: 100,
      maxCm: 250,
    };
  },
  mounted() {
    const key = this.$utils.getKey(this.templateData.screenName);
    const height = this.$store.state.onBoardingRecordInfo[key];
    const heightUnitKey = this.$utils.getKey("heightUnit");
    if (height) {
      const heightUnit = this.$store.state.onBoardingRecordInfo[heightUnitKey];
      this.currentType = (heightUnit === webConfig.heightCmUnitNumber) ? 'cm' : 'in';
      this.cmValue = height;
      this.cmToFtIn();
    }
  },
  computed: {
    //获取inputTips文本提示
    getInputTip() {
      if (this.currentType == 'cm') {
        return `${this.$t.EnterAValueFrom} ${this.minCm} ${this.$t.to} ${this.maxCm} cm`
      } else {
        return `${this.$t.EnterAValueFrom} ${this.minFt} ${this.$t.to} ${this.maxFt} ft ${this.maxIn} in`
      }

    },
    // 返回提示的可见性状态
    isTipVisible() {
      const value = this.currentType == 'cm' ? this.cmValue : this.ftValue
      return (value || this.inValue) && !this.validInputValue()
    }
  },
  watch: {
    ftValue(newValue, oldValue) {
      this.updateInputWidth('ft');
      this.ftValue = this.$utils.inputValueWatch(newValue, oldValue, 9);
    },
    inValue(newValue, oldValue) {
      this.updateInputWidth('in');
      this.inValue = this.$utils.inputValueWatch(newValue, oldValue, 11);
    },
    cmValue(newValue, oldValue) {
      this.updateInputWidth('cm');
      this.cmValue = this.$utils.inputValueWatch(newValue, oldValue, 999);
    },
  },
  methods: {
    // 更新输入框宽度
    updateInputWidth(type) {
      this.$nextTick(() => {
        const span = this.$refs.weightInputSpanRef;
        const value = type === 'cm' ? this.cmValue : type == 'ft' ? this.ftValue : this.inValue;
        let inputWitdh
        if (span) {
          span.textContent = value || '0';
          inputWitdh = span.offsetWidth + 10;// 这里的 10 是额外宽度，用于确保足够的空间
          type == 'cm' ? this.iptWidth3 = inputWitdh : type == 'ft' ? this.iptWidth1 = inputWitdh : this.iptWidth2 = inputWitdh
        }
      })
    },
    // 焦点事件 获取焦点时值为0则改为‘’，失去焦点值为‘’改为0
    clearDefaultValue(e, type) {
      if (type == 1) {
        e.target.value == 0 ? e.target.value = '' : ''
      } else {
        e.target.value = e.target.value ? e.target.value : 0
      }
    },
    // ft/in 转cm
    ftInToCm() {
      const ftValue = this.ftValue || 0;
      const inValue = this.inValue || 0;
      let cmValue = Math.round(ftValue * this.$utils.initFt + inValue * this.$utils.initIn) || 0;
      if (cmValue < this.minCm) {
        cmValue = this.minCm;
      } else if (cmValue > this.maxCm) {
        cmValue = this.maxCm;
      }
      this.cmValue = cmValue;
    },
    // cm转ft/in
    cmToFtIn() {
      let ftValue = 0;
      let inValue = 0;
      if (this.cmValue) {
        ftValue = Math.floor(this.cmValue / this.$utils.initFt);
        inValue = Math.round((this.cmValue % this.$utils.initFt) / this.$utils.initIn);
        if (inValue >= 12) {
          ftValue += 1;
          inValue = 0;
        }
      }
      ftValue = Math.min(Math.max(ftValue, this.minFt), this.maxFt);
      inValue = Math.min(Math.max(inValue, this.minIn), this.maxIn);
      this.ftValue = ftValue;
      this.inValue = inValue;
    },
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "in") {
        this.cmToFtIn();
      } else {
        this.ftInToCm();
      }
    },
    validInputValue() {
      let value = this.currentType === 'cm' ? this.cmValue : this.ftValue;
      value = Number(value);
      const minValue = this.currentType === 'cm' ? this.minCm : this.minFt;
      const maxValue = this.currentType === 'cm' ? this.maxCm : this.maxFt;
      return value >= minValue && value <= maxValue;
    },
    //验证
    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "in") {
          this.ftInToCm();
        }
        this.updateOnBoardingRecord({
          key: this.$utils.getKey("heightUnit"),
          value: this.currentType == "cm" ? webConfig.heightCmUnitNumber : (webConfig.heightCmUnitNumber == 1 ? 0 : 1),
        });
        this.selectOption(this.cmValue * 1, isNext);
      }
    },
  }
};
</script>
<style scoped lang="scss">
.templateHeight {
  .type-tabs {
    margin: 0 auto;
    margin-top: 26px;
    padding: 2px;
    display: flex;
    width: 132px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 132px;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-inline-end: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      /deep/.van-cell {
        padding: 0 !important;
      }

      /deep/ .van-cell::after {
        border: none;
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }

  .weightInputSpan {
    visibility: hidden;
    white-space: pre;
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;

  }

  .ipt-tip {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }

  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    max-width: 550px;
    border-radius: 12px;
    background: #e0e0ea;

    .title {
      font-weight: 600;

      .img {
        margin-top: -5px;
        margin-inline-end: 8px;
      }

      position: relative;
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .templateHeight .height-tip .title ::after {
    top: 0;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import mainConfig from "@/config/onboarding.config/main.json"

Vue.use(Router)

const routes = require('./routes.js');
const router = new Router({
    routes,
    mode: 'history'
})
router.afterEach((to, from) => {
    window.scrollTo(0, 0);
    const title = to.path
        .replace(/\//g, '')  // 去掉斜杠
        .split('-')  // 按照连字符分割
        // .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // 首字母大写
        .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())  // 只大写第一个单词
        .join(' ') + ` - ${mainConfig.appName}` || mainConfig.appName;  // 默认标题
    document.title = title;  // 设置页面标题
});
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => console.log(err))
}

export default router

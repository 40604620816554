var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"templateRef",staticClass:"templateSingleOption"},[_c('Head'),(_vm.animationLoading && _vm.templateData)?_c('div',{staticClass:"template-content"},[_c('div',{staticClass:"animation"},[_c('div',{staticClass:"title title_1 tc",domProps:{"innerHTML":_vm._s(_vm.$utils.redirectUrlKeyword(_vm.templateData.languageConfig[_vm.$language].mainTitle))}}),_c('div',{staticClass:"sub-title text_2 tc",domProps:{"innerHTML":_vm._s(_vm.templateData.languageConfig[_vm.$language].mainText)}}),(_vm.templateData.mainImage)?_c('img',{staticClass:"main-img flip-image",attrs:{"src":require(`@img/template/${_vm.templateData.mainImage}`),"alt":""}}):_vm._e(),_c('div',{staticClass:"option-group"},_vm._l((_vm.templateData.languageConfig[_vm.$language].optionList),function(item,index){return _c('div',{staticClass:"option-item",class:{
          active: item.originName == _vm.templateValue, 'margin-inline-start': _vm.templateData.imageFull && _vm.templateData.imagePosition == 'left',
          'margin-right': _vm.templateData.imageFull && _vm.templateData.imagePosition == 'right', 'shrink-grow-effect': _vm.activeIndex === index
        },on:{"click":function($event){$event.stopPropagation();return _vm.optionClick(item.originName, index, item.option)}}},[_c('div',{staticClass:"option flex justify-between align-center pointer"},[(_vm.templateData.imageList && _vm.templateData.imageList[index] && _vm.templateData.imagePosition == 'left')?_c('img',{staticClass:"option-img left-img",attrs:{"height":_vm.templateData.imageSize,"src":require(`@img/template/${_vm.templateData.imageList[index]}`),"alt":""}}):_vm._e(),_c('div',{staticClass:"text-warp flex-auto"},[_c('div',{staticClass:"text theme_01 text_1",domProps:{"innerHTML":_vm._s(item.option)}}),_c('div',{staticClass:"sub-text theme_02 text_3",domProps:{"innerHTML":_vm._s(item.subOption)}})]),(
              _vm.templateData.imageList &&
              _vm.templateData.imageList[index] && _vm.templateData.imagePosition == 'right'
            )?_c('img',{staticClass:"option-img right-img",attrs:{"height":_vm.templateData.imageSize,"src":require(`@img/template/${_vm.templateData.imageList[index]}`),"alt":""}}):_vm._e()])])}),0)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
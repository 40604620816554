<template>
    <div class="head-box">
        <prosess v-if="templalteIntro && templalteIntro.isStepPage">
        </prosess>
        <logo v-else :isCenter="isCenter" :bgColor="bgColor" :templalteIntro="templalteIntro"
            :isHideTitle="isHideTitle"></logo>
    </div>
</template>

<script>
import logo from "@/components/onBody/components/logo";
import prosess from "@/components/onBody/components/prosess";

export default {
    computed: {
        templalteIntro() {
            return this.$store.getters.currentTemplateFlow
        }
    },
    props: {
        // 是否隐藏标题
        isHideTitle: {
            default: false
        },
        // 背景颜色
        bgColor: {
            default: 'bgColor'
        },
        isCenter: {
            default: false
        }
    },
    components: {
        prosess,
        logo
    },
    methods: {

    },
};
</script>

<style scoped>
.head-box {
    padding-top: 10px;
}
</style>
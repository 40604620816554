const routes = [
    {
        path: '/',
        redirect: '/main-page'
    },
    {
        path: '/main-page',
        name: 'mainPage',
        component: () =>
            import('@/views/main-page')
    },
    {
        path: '/login-page',
        name: 'loginPage',
        component: () =>
            import('@/views/login-page')
    },
    {
        path: '/about-us-page',
        name: 'aboutUsPage',
        component: () =>
            import('@/views/about-us-page')
    },
    {
        path: '/user-review-page',
        name: 'userReviewPage',
        component: () =>
            import('@/views/user-review-page')
    },
    {
        path: '/generated-questionnaire-page',
        name: 'generatedQuestionnairePage',
        component: () =>
            import('@/views/onboarding/generated-questionnaire-page.vue')
    },
    {
        path: '/brand-page',
        name: 'brandPagePage',
        component: () =>
            import('@/views/onboarding/brand-page.vue')
    },
    {
        path: '/policy-page',
        name: 'policyPage',
        component: () =>
            import('@/views/policy-page')
    },
    {
        path: '/contact-us-page',
        name: 'contactUsPage',
        component: () =>
            import('@/views/contact-us-page')
    },
    {
        path: '/modify-email-page',
        name: 'modifyEmailPage',
        component: () =>
            import('@/views/modify-email-page')
    },
    {
        path: '/reset-password-page',
        name: 'resetPasswordPage',
        component: () =>
            import('@/views/reset-password-page')
    },
    {
        path: '/paywall-page',
        name: 'paywallPage',
        component: () =>
            import('@/views/paywall/index')
    },
    {
        path: '/discount-paywall-page',
        name: 'paywallDiscountPage',
        component: () =>
            import('@/views/paywall/discount-paywall-page')
    },
    {
        path: '/payment-succeed-page',
        name: 'paymentSucceedPage',
        component: () =>
            import('@/views/payment-succeed-page')
    },
    {
        path: '/create-account-page',
        name: 'createAccountPage',
        component: () =>
            import('@/views/create-account-page')
    },
    {
        path: '/user-profile-page',
        name: 'userProfilePage',
        component: () =>
            import('@/views/user-profile-page')
    },
    {
        path: '/cancel-plan-survey-page',
        name: 'cancelPlanSurveyPage',
        component: () =>
            import('@/views/cancel-plan-survey-page')
    },
    {
        path: '/cancel-confirm-page',
        name: 'cancelConfirmPage',
        component: () =>
            import('@/views/cancel-confirm-page')
    },
    {
        path: '/get-start-page',
        name: 'getStartPage',
        component: () =>
            import('@/views/onboarding/get-start-page.vue')
    },
    {
        path: '/offer-page',
        name: 'offerPage',
        component: () =>
            import('@/views/offer-page.vue')
    },

]


//使用CommonJS导出，便于vue.config.js使用
module.exports = routes
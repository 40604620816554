<template>
  <div class="templateTargetWeight">

    <Head></Head>
    <div class="template-content" v-if="animationLoading && templateData">
      <div class="animation">
        <div class="title title_1 tc" v-html="templateData.languageConfig[$language].mainTitle"></div>
        <div class="type-tabs">
          <div class="type-item" @click="typeChange(item)" :class="{ active: currentType == item }"
            v-for="item in typeList" :key="item">
            {{ item }}
          </div>
        </div>
        <div class="ipt-warp flex" v-show="currentType == 'kg'">
          <div class="ipt-item title_3">
            <van-field type="number" @focus="clearDefaultValue($event, 1)" @blur="clearDefaultValue($event, 2)"
              :style="{ width: inputWidth + 'px' }" :class="{ noData: kgValue == 0 }" class="ipt" v-model="kgValue" />
            <div>kg</div>
          </div>
        </div>
        <div class="ipt-warp flex" v-show="currentType == 'lbs'">
          <div class="ipt-item title_3">
            <van-field type="number" @focus="clearDefaultValue($event, 1)" @blur="clearDefaultValue($event, 2)"
              :style="{ width: inputWidth + 'px' }" :class="{ noData: kgValue == 0 }" class="ipt" v-model="lbsValue" />
            <div>lbs</div>
          </div>
        </div>
        <span class="weightInputSpan" ref="weightInputSpanRef"></span>
        <div class="ipt-tip title_3 tip-color tc">
          <span class="amimation-height" :class="isTipVisible ? 'tip-visible' : 'hidden'">
            {{ getInputTip }}</span>
        </div>
        <div class="amimation-height" :class="heigthTip ? 'weight-visible' : 'hidden'">
          <div class="height-tip" v-if="tipConfig" :style="{ background: tipConfig.background }">
            <div class="flex align-center">
              <img class="img" width="24" :src="require(`@img/${tipConfig.icon}`)" alt="" />
              <div class="flex-auto">
                <strong class="title text_2" style="font-weight: 700">
                  {{ tipConfig.name }}</strong>
                <span class="title text_2" style="font-weight: 400">
                  {{ tipConfig.title }}
                </span>
              </div>
            </div>
            <div class="sub-title text_3">
              {{ tipConfig.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-warp" v-show="animationLoading && templateData">
      <div @click="validateClick(), setOptionClickAnimation(-1)"
        :class="{ active: validInputValue(), 'shrink-grow-effect': activeIndex === -1 }" class="button text_1"
        v-html="templateData.languageConfig[$language].buttonText"></div>
    </div>
  </div>
</template>
<script>
import Head from "./components/head.vue"
import mixinTemplate from "@/mixin";
import webConfig from "@/config/web.config/index";
export default {
  name: "templateTargetWeight",
  mixins: [mixinTemplate],
  components: {
    Head,
  },
  data() {
    return {
      heigthTip: false,
      inputWidth: 40,
      typeList: ["lbs", "kg"],
      currentType: "lbs",
      kgValue: 0,
      lbsValue: 0,
      minKg: 30,
      maxKg: 250,
      minLbs: 66,
      maxLbs: 551,
      tipConfig: null,
      isActive: false,
    };
  },
  watch: {
    kgValue(newValue, oldValue) {
      this.updateWeight('kg', newValue, oldValue);
    },
    lbsValue(newValue, oldValue) {
      this.updateWeight('lbs', newValue, oldValue);
    }
  },
  mounted() {
    const { onBoardingRecordInfo } = this.$store.state;
    const key = this.$utils.getKey(this.templateData.screenName);
    const weightUnitKey = this.$utils.getKey('weightUnit');
    this.currentType = onBoardingRecordInfo[weightUnitKey] === webConfig.weightKgUnitNumber ? 'kg' : 'lbs';

    const storedWeight = onBoardingRecordInfo[key];
    if (storedWeight) {
      if (webConfig.weightType === 0) {
        this.kgValue = storedWeight;
        this.lbsValue = this.$utils.kgToLbs(storedWeight, this.minLbs, this.maxLbs);
      } else {
        this.lbsValue = storedWeight;
        this.kgValue = this.$utils.lbsToKg(storedWeight, this.minKg, this.maxKg);
      }
    }
  },
  computed: {
    //获取inputTips文本提示
    getInputTip() {
      const unit = this.currentType === 'kg' ? 'KG' : 'LBS';
      const minValue = this.currentType === 'kg' ? this.minKg : this.minLbs;
      const maxValue = this.currentType === 'kg' ? this.maxKg : this.maxLbs;
      return `${this.$t.EnterAValueFrom} ${minValue} ${this.$t.to} ${maxValue} ${unit}`;
    },
    // 返回提示的可见性状态
    isTipVisible() {
      const value = this.currentType === 'kg' ? this.kgValue : this.lbsValue;
      return Number(value) && !this.validInputValue();
    }
  },
  methods: {
    updateWeight(type, newValue, oldValue) {
      if (type === 'kg') {
        this.kgValue = this.inputValueWatch(newValue, oldValue, this.minKg, this.maxKg, 999);
      } else if (type === 'lbs') {
        this.lbsValue = this.inputValueWatch(newValue, oldValue, this.minLbs, this.maxLbs, 999);
      }
      this.getWeightTipInfo();
      this.updateInputWidth();
    },
    // 更新输入框宽度
    updateInputWidth() {
      this.$nextTick(() => {
        const span = this.$refs.weightInputSpanRef;
        const value = this.currentType === 'kg' ? this.kgValue : this.lbsValue;
        if (span) {
          span.textContent = value || '0';
          this.inputWidth = span.offsetWidth + 10;// 这里的 10 是额外宽度，用于确保足够的空间
        }
      })
    },
    // 焦点事件 获取焦点时值为0则改为‘’，失去焦点值为‘’改为0
    clearDefaultValue(e, type) {
      if (type == 1) {
        e.target.value == 0 ? e.target.value = '' : ''
      } else {
        e.target.value = e.target.value ? e.target.value : 0
      }
    },
    validInputValue() {
      let bool = false;
      if (this.currentType == 'lbs') {
        bool = (this.lbsValue && this.lbsValue >= this.minLbs && this.lbsValue <= this.maxLbs);
      } else {
        bool = (this.kgValue && this.kgValue >= this.minKg && this.kgValue <= this.maxKg);
      }
      return bool
    },
    // 监听输入框value变化
    inputValueWatch(newValue, oldValue) {
      newValue = newValue ? newValue.toString() : ''
      // 去除前导零（只针对整数部分）
      if (newValue.includes('.')) {
        const parts = newValue.split('.');
        parts[0] = parseInt(parts[0], 10).toString(); // 处理整数部分
        newValue = parts.join('.');
      } else {
        newValue = parseInt(newValue, 10).toString(); // 处理纯整数
      }
      // 只允许数字和小数点
      let formattedValue = newValue.replace(/[^\d.]/g, '');
      // 限制只能有一个小数点
      const parts = formattedValue.split('.');
      if (parts.length > 2) {
        formattedValue = `${parts[0]}.${parts[1]}`;
      }
      // 限制小数位数为1位
      if (parts[1] && parts[1].length > 1) {
        formattedValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
      }
      // 限制最多3位数字（包括整数和小数部分）
      if (formattedValue.length > 3 && !formattedValue.includes('.')) {
        formattedValue = formattedValue.slice(0, 3);
      } else if (formattedValue.length > 5) {  // 例子: "999.9"
        //防止输入完成后重新聚焦修整数部分数字大于max
        if (Math.floor(formattedValue)) {
          formattedValue = oldValue
        } else {
          formattedValue = formattedValue.slice(0, 5);
        }
      }
      return formattedValue
    },
    // 通过bmi和身高获取标准体重
    getStandardWeight(height, type = "min") {
      if (!height) return "";
      //type:min: 健康标准值最小值  max:健康标准值最大值
      let weight = type == "min" ? this.$utils.initUnderweight : this.$utils.initNormal;
      let res = parseInt(weight * (height / 100) ** 2);
      return res;
    },
    // 获取体重变化百分比
    getWeightPercentage(weight, goalWeight) {
      if (weight === goalWeight) {
        return 0;
      }
      const ratio = goalWeight / weight;
      return weight > goalWeight
        ? 100 - Math.round(ratio * 100)
        : Math.round((ratio - 1) * 100);
    },

    // 获取体重变化提示信息
    getWeightTipInfo() {
      let tipConfig = null;

      if (this.currentType === "lbs") {
        this.kgValue = this.$utils.lbsToKg(this.lbsValue);
      }

      if (!this.kgValue) {
        this.heigthTip = false;
        setTimeout(() => {
          this.tipConfig = tipConfig;
        }, 300);
        return;
      }

      const weight = webConfig.weightType === 0
        ? Number(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')])
        : this.$utils.lbsToKg(Number(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')]));

      const height = Number(this.$store.state.onBoardingRecordInfo[this.$utils.getKey('heightScreen')]);
      const goalWeight = Number(this.kgValue);

      if (weight && height && goalWeight) {
        const weightPercentage = this.getWeightPercentage(weight, goalWeight); // 体重变化百分比
        const minStandardWeight = this.getStandardWeight(height, "min"); // 体重健康值最小值
        const maxStandardWeight = this.getStandardWeight(height, "max"); // 体重健康值最大值

        if (weight === goalWeight) {
          tipConfig = {
            name: this.$t.KEEPFIT,
            icon: "target1.png",
            background: "#CFDFD1",
            text: this.$t.targetWeightDescription1,
            title: this.$t.targetWeightTitle1,
          };
        } else if (weight < goalWeight) {
          tipConfig = {
            name: this.$t.GETSTRONG,
            icon: "target7.png",
            background: "#F3EBD4",
            text: this.$t.targetWeightDescription2,
            title: this.$utils.replaceTextValue(this.$t.targetWeightTitle2, weightPercentage),
          };
        } else if (goalWeight < minStandardWeight) {
          tipConfig = {
            name: this.$t.LOWWEIGHTALERT,
            icon: "target6.png",
            background: "#EED6D6",
            text: this.$utils.replaceTextValue(this.$t.targetWeightDescription3, minStandardWeight, maxStandardWeight),
            title: this.$utils.replaceTextValue(this.$t.targetWeightTitle3, weightPercentage),
          };
        } else if (weight > goalWeight) {
          const percentageRanges = [
            { max: 5, config: { name: this.$t.EASYWIN, icon: "target2.png", background: "#D4E4F3", text: this.$t.targetWeightDescription4, title: this.$utils.replaceTextValue(this.$t.targetWeightTitle4, weightPercentage) } },
            { max: 10, config: { name: this.$t.REALISTICTARGET, icon: "target3.png", background: "#D4E4F3", text: this.$t.targetWeightDescription5, title: this.$utils.replaceTextValue(this.$t.targetWeightTitle5, weightPercentage) } },
            { max: 20, config: { name: this.$t.HEALTHBENEFITS, icon: "target4.png", background: "#CFDFD1", text: this.$t.targetWeightDescription6, title: this.$utils.replaceTextValue(this.$t.targetWeightTitle6, weightPercentage) } },
            { max: Infinity, config: { name: this.$t.CHALLENGINGGOAL, icon: "target5.png", background: "#CFDFD1", text: this.$t.targetWeightDescription7, title: this.$utils.replaceTextValue(this.$t.targetWeightTitle7, weightPercentage) } },
          ];

          tipConfig = percentageRanges.find(range => weightPercentage < range.max).config;
        }
      }
      this.heigthTip = true;
      this.tipConfig = tipConfig;
    },
    typeChange(item) {
      this.currentType = item;
      if (this.currentType == "kg") {
        this.kgValue = this.$utils.lbsToKg(this.lbsValue, this.minKg, this.maxKg);
      } else {
        this.lbsValue = this.$utils.kgToLbs(this.kgValue, this.minLbs, this.maxLbs);
      }
      this.updateInputWidth()
    },
    validateClick(isNext = true) {
      if (this.validInputValue()) {
        //输入是否符合规则
        if (this.currentType == "lbs") {
          this.kgValue = this.$utils.lbsToKg(this.lbsValue);
        }
        this.updateOnBoardingRecord({
          key: 'weightUnit',
          value: this.currentType == 'kg' ? webConfig.weightKgUnitNumber : (webConfig.weightKgUnitNumber == 1 ? 0 : 1)
        })
        this.selectOption(webConfig.weightType == 0 ? this.kgValue * 1 : this.lbsValue * 1, isNext);
      }
    },
  }
};
</script>
<style scoped lang="scss">
.templateTargetWeight {
  .type-tabs {
    margin: 50px auto;
    padding: 2px;
    display: flex;
    width: 220px;
    height: 45px;
    background-color: var(--theme_03);
    border-radius: 8px;

    .type-item {
      width: 50%;
      font-size: 14px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: var(--theme_01);
      border-radius: 8px;
      cursor: pointer;
    }

    .active {
      color: var(--theme_03);
      background-color: var(--mainColor);
    }
  }

  .ipt-warp {
    margin: 0 auto;
    margin-top: 80px;
    text-align: center;
    justify-content: center;

    .ipt-item {
      display: flex;
      align-items: flex-end;
      font-weight: 700;

      color: var(--theme_01);

      div {
        font-size: 14px;
        font-weight: 600;
      }

      .ipt {
        background: none;
        min-width: 35px;
        margin-inline-end: 5px;

        /deep/.van-field__control {
          display: inline;
          width: 100%;
          height: 55px;
          font-size: 48px;
          line-height: 52px;
          font-weight: 700;
          text-align: center;
          color: var(--theme_01);
        }
      }

      /deep/.van-cell {
        padding: 0 !important;
      }

      /deep/ .van-cell::after {
        border: none;
      }

      .noData {
        /deep/.van-field__control {
          color: var(--theme_02);
        }
      }
    }
  }

  .weightInputSpan {
    visibility: hidden;
    white-space: pre;
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;

  }

  .ipt-tip {
    margin: 0 auto;
    margin-top: 5px;
    height: 18px;
  }

  .height-tip {
    margin: 20px auto;
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;

    .img {
      margin-top: -5px;
      margin-inline-end: 5px;
    }

    .title_2 {
      margin-inline-end: 5px;
    }

    .icon {
      margin-inline-end: 5px;
      height: 24px;
    }

    .title {
      flex: 1;
      position: relative;
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .templateTargetWeight .height-tip .title[data-v-4d1c6a21]::after {
    top: 0;
  }
}
</style>

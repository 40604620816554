<template>
  <div class="bmi-box">
    <table class="bmi-table">
      <tr v-for="oTr in 6" :key="oTr">
        <td v-for="oTd in 12" :key="oTd"></td>
      </tr>
    </table>
    <div class="bmi-top flex justify-between align-center">
      <div class="title_3 theme_01">{{ $t.BodyMass }}</div>
      <div class="text_4 theme_02">{{ $t[bmiName] }} - {{ bmi }}</div>
    </div>
    <div class="bmi">
      <div class="bmi-scale flex justify-between align-center">
        <div v-for="item in bmiScaleList" :key="item">{{ item }}</div>
      </div>
      <div class="bmi-symbol" :style="symbolStyle">
        <div class="bim-label text_4" :class="{ 'min-label': progressValue < 10, 'max-label': progressValue > 80 }">
          {{ $t.You }}&nbsp;-&nbsp;{{ bmi }}
        </div>
        <div class="bim-point"><i></i></div>
      </div>
      <div class="bmi-bar flip-image"></div>
      <div class="bmi-name  flex justify-between">
        <span v-for="(item, index) in $t.bmiNameList" :key="index" :class="{ 'bmi-text': index == bmiIndex }">{{ item
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bmi",
  props: {
    bmi: {
      default: 0,
    },
    bmiName: {
      default: ""
    },
    bmiIndex: {
      default: 0
    },
    progressNumber: {
      default: 0
    }
  },
  data() {
    return {
      bmiScaleList: [15, 18.5, 25, 30, 40],
      progressValue: 0,
      bmiColors: {
        underweight: '#F24822',
        normal: "#34C759",
        overweight: "#FFA800",
        obese: "#F24822"
      }
    };
  },
  computed: {
    // 动态计算样式
    symbolStyle() {
      // 根据语言方向选择 left 或 right
      const direction = this.$language == 'ar' ? 'right' : 'left';
      return {
        [direction]: this.progressValue + '%'  // 动态绑定 left 或 right
      };
    }
  },
  mounted() {
    setTimeout(() => {
      this.progressValue = this.progressNumber


    }, 100);

  }
};
</script>
<style scoped lang="scss">
.bmi-box {
  position: relative;
  padding-top: 15px;
  padding-inline-start: 6%;
  padding-inline-end: 8%;
  height: 100%;
  overflow: hidden;

  .bmi-table {
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    bottom: -10px;
    z-index: 1;
    width: 100%;
    height: calc(100% + 30px);
    border-collapse: collapse;
    border-spacing: 0;
    border: none;
    opacity: 0.7;

    td {
      border: 1px dashed #eee;
    }
  }

  .bmi-top {
    // width: calc(100% + 12px);
    margin-bottom: 30px;

    .underweight {
      color: #F24822;
    }
  }

  .bmi {
    box-sizing: border-box;
    position: relative;
    z-index: 9;

    .bmi-scale {
      // width: calc(100% + 12px);
      margin-bottom: 15px;
      font-size: 14px;

    }

    .bmi-symbol {
      position: absolute;
      left: 0;
      top: 18px;
      transition: all ease-out 1s;

      .bim-label {
        padding: 0 10px;
        position: absolute;
        // left: 50%;
        bottom: 37px;
        margin-inline-start: -36px;
        height: 30px;
        line-height: 32px;
        text-align: center;
        color: var(--theme_03);
        background: var(--theme_01);
        border-radius: 4px;
        cursor: pointer;
        white-space: nowrap;
      }

      .min-label {
        margin-inline-start: -17px !important;
      }

      .max-label {
        margin-inline-start: -50px;
      }

      .bim-point {
        position: relative;
        top: 8px;
        width: 25px;
        height: 25px;
        z-index: 999;
        background: var(--theme_03);
        border-radius: 50%;
        box-shadow: 0 0 5px #c3c2c2;
        transform: translateX(-50%);

        i {
          position: absolute;
          display: block;
          left: 0;
          margin-inline-start: 29%;
          top: -23px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 10px solid var(--theme_01);
        }
      }

      .bim-point::after {
        position: absolute;
        left: 10px;
        top: -19px;
        z-index: -1;
        height: 20px;
        width: 2px;
        content: "";
        background-color: var(--theme_01);

      }
    }

    .bmi-bar {
      // width: calc(100% + 12px);
      height: 6px;
      border-radius: 16px;
      background-image: linear-gradient(to right, #81c24d, #ffdd7a, #f75836);
    }

    .bmi-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 24px;
      margin-top: 15px;
      color: var(--theme_02);
      font-size: 15px;

      .bmi-text {
        color: var(--theme_01);
      }
    }
  }
}

@media (max-width: 576px) {
  .bmi-box {
    padding-top: 8px;
    padding-inline-start: 6%;
    padding-inline-end: 6%;

    .bmi .bmi-symbol {
      .bim-label {
        min-width: 77px;
        // margin-inline-start: -35px;
        height: 25px;
        line-height: 25px;
        bottom: 37px;
      }

      .max-label {
        margin-inline-start: -65px;
      }
    }

    .bmi-top {
      margin-bottom: 25px;
    }

    .bmi .bmi-name {
      font-size: 10px;
    }
  }
}

[dir="rtl"] .bmi-box .bmi .bmi-symbol .bim-point {
  transform: translateX(50%);
}

[dir="rtl"] .bmi-box .bmi .bmi-symbol .bim-point i {
  right: 0;
}
</style>
